





























import { Component, Inject, Vue } from 'vue-property-decorator'
import { ProjectDetailViewModel } from '../viewmodels/project-detail-viewmodel'

@Component({
  components: {
    LabelStatus: () => import('./label-status.vue'),
  },
})
export default class AboutContent extends Vue {
  @Inject() vm!: ProjectDetailViewModel
}
